import Icon from "@ant-design/icons";
import arrowSVG from "~/assets/icons/fail_load.svg?react";
import UIKitButton from "../../UIKit/Button";

import { useBotStore } from "~/stores";
import useModeClassName from "~/hooks/useModeClassName";
import classNames from "classnames";

import "./index.scss";

export default function BotInitError({ forceHide }: { forceHide?: boolean }) {
  const modeClassName = useModeClassName();
  const { setRootRefresh } = useBotStore();
  function handleRefresh() {
    setRootRefresh(true);
  }

  return (
    <div
      className={classNames("bot-error-boundary", { ...modeClassName })}
      style={forceHide ? { visibility: "hidden" } : {}}
    >
      <Icon component={arrowSVG}></Icon>
      <div className="desc">Failed to load page</div>
      <UIKitButton onClick={handleRefresh}>Refresh</UIKitButton>
    </div>
  );
}
