import { useEffect, useRef, useState } from "preact/compat";

import { useBotStore } from "~/stores";
import { ProductCompareItem } from "~/types";
import UIKitButton from "~/components/UIKit/Button";
import "./index.scss";

export default function CompareProductModal() {
  const boxDOMRef = useRef<HTMLDivElement>(null);
  const modalDOMRef = useRef<HTMLDivElement>(null);
  const { compareDialogVisible, toggleCompareDialog, compareData, setCompareData } = useBotStore();
  const [hoveredKey, setHoveredKey] = useState("");

  useEffect(() => {
    let listener: EventListener;

    if (boxDOMRef.current) {
      listener = (event: any) => {
        if (!modalDOMRef.current?.contains(event.target)) {
          hideCompare();
        }
      };
      boxDOMRef.current.addEventListener("click", listener);
    }

    return () => {
      if (listener) {
        document.removeEventListener("click", listener);
      }
    };
  }, []);

  useEffect(() => {
    // 获取所有 name 节点
    const namesDOM = Array.from(document.querySelectorAll("div.compare-box div.name"));
    namesDOM.forEach((container) => {
      // 对每个 name 节点，使其内容不超过 2 行，超过即显示 ...
      // const name = container.textContent as string
      const containerHeight = container.clientHeight;
      const lineHeight = parseInt(window.getComputedStyle(container).lineHeight);
      const textHeight = container.scrollHeight;
      if (textHeight > containerHeight) {
        const maxLines = 2;
        const maxTextHeight = maxLines * lineHeight;

        // 截断文本
        while (container.scrollHeight > maxTextHeight) {
          container.textContent = (container.textContent as string).replace(/\W*\s(\S)*$/, "...");
        }
      }
    });
  }, []);

  function hideCompare(e?: MouseEvent) {
    e?.stopPropagation();
    setCompareData([]);
    toggleCompareDialog();
  }

  if (!compareDialogVisible) return null;

  function getAttributes(keyName: string, targetKey: keyof ProductCompareItem) {
    return compareData.map((item: ProductCompareItem) => {
      return {
        key: keyName,
        value: item[targetKey],
      };
    });
  }

  return (
    <div className="compare-box" ref={boxDOMRef}>
      <div className="modal" ref={modalDOMRef}>
        <div className="modal-header">
          <div onClick={hideCompare}>Comparison</div>
          <div className="close" onClick={hideCompare}>
            <svg
              width="20px"
              height="20px"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M5.293 5.293a1 1 0 0 1 1.414 0L12 10.586l5.293-5.293a1 1 0 1 1 1.414 1.414L13.414 12l5.293 5.293a1 1 0 0 1-1.414 1.414L12 13.414l-5.293 5.293a1 1 0 0 1-1.414-1.414L10.586 12 5.293 6.707a1 1 0 0 1 0-1.414z"
                fill="#0D0D0D"
              />
            </svg>
          </div>
        </div>
        <div className="modal-body">
          <div className="compare-list">
            {/* 顶部 title, image, buy 按钮 */}
            <div className="part top-part">
              {compareData.map((item: ProductCompareItem, index: number) => {
                return (
                  <div className="item" key={index} onClick={() => window.open(item.product_url)}>
                    <img src={item.product_images[0].url} alt="" />
                    <div className="price">$ {item.product_price}</div>
                    <div className="name-box" title={item.product_name}>
                      <div className="name">{item.product_name}</div>
                    </div>
                    <UIKitButton>Buy Now</UIKitButton>
                  </div>
                );
              })}
            </div>

            <div className="attribute-part">
              {getAttributes("Brand", "product_brand").map((item, index) => {
                return (
                  <Attribute
                    key={index}
                    name={item.key}
                    value={item.value as string}
                    hoveredKey={hoveredKey}
                    setHoveredKey={setHoveredKey}
                  />
                );
              })}
            </div>
          </div>

          <div className="bottom hide">
            <UIKitButton onClick={hideCompare}>Back</UIKitButton>
          </div>
        </div>
      </div>
    </div>
  );
}

interface IAttributeProps {
  name: string;
  value: string;
  hoveredKey?: string;
  setHoveredKey: (name: string) => void;
}

function Attribute({ name, value, hoveredKey, setHoveredKey }: IAttributeProps) {
  return (
    <div className="item">
      <div className="attribute-name">{name}</div>
      <div
        className="attribute-value"
        style={{ backgroundColor: hoveredKey === name ? "var(--neutral-3)" : "" }}
        onMouseEnter={() => setHoveredKey(name)}
        onMouseLeave={() => setHoveredKey("")}
      >
        {value}
      </div>
    </div>
  );
}
