import { h, cloneElement, render, hydrate, createRef } from "preact";
import type {
  FunctionComponent,
  ComponentClass,
  FunctionalComponent,
  VNode,
  RefObject,
} from "preact";

type ComponentDefinition = FunctionComponent<any> | ComponentClass<any> | FunctionalComponent<any>;

interface Options {
  shadow: boolean;
  mode?: "open" | "closed";
  cssText?: string;
}

interface Props {
  context?: unknown;
  children?: VNode;
  ref?: RefObject<ComponentDefinition>;
  [key: string]: unknown;
}

function toCamelCase(str: string) {
  return str.replace(/-(\w)/g, (_, c) => (c ? c.toUpperCase() : ""));
}

export default function register(
  Component: ComponentDefinition,
  tagName: string,
  propNames?: string[],
  options?: Options
) {
  const tag = tagName || Component.displayName || Component.name;
  if (customElements.get(tag)) return;

  class PreactCustomElement extends HTMLElement {
    _vdom!: VNode<any> | null;
    _vdomComponent: ComponentDefinition;
    _root: ShadowRoot | PreactCustomElement;
    _props: Props;
    _listener!: (event: CustomEvent<{ context?: unknown }>) => void;

    getChildContext!: () => unknown;
    ref?: HTMLElement;

    static observedAttributes = propNames || [];

    constructor() {
      super();
      this._root = this;
      this._vdomComponent = Component;
      if (options?.shadow) {
        this._root = this.attachShadow({ mode: options?.mode || "open" });
      }
      if (this._root && options?.cssText) {
        const style = document.createElement("style");
        style.textContent = options.cssText;
        this._root.appendChild(style);
      }

      this._props = { ref: createRef(), _shadowRoot: this };

      PreactCustomElement.observedAttributes.forEach((name) => {
        if (name in PreactCustomElement.prototype) return;
        Object.defineProperty(PreactCustomElement.prototype, name, {
          get() {
            return this._vdom.props[name];
          },
          set(v) {
            if (this._vdom) {
              this.attributeChangedCallback(name, null, v);
            } else {
              if (!this._props) this._props = {};
              this._props[name] = v;
              this.connectedCallback();
            }

            // Reflect property changes to attributes if the value is a primitive
            const type = typeof v;
            if (v == null || type === "string" || type === "boolean" || type === "number") {
              this.setAttribute(name, v);
            }
          },
        });
      });
    }

    connectedCallback() {
      // Obtain a reference to the previous context by pinging the nearest
      // higher up node that was rendered with Preact. If one Preact component
      // higher up receives our ping, it will set the `detail` property of
      // our custom event. This works because events are dispatched
      // synchronously.
      this.dispatchEvent(
        new CustomEvent("_preact", { detail: {}, bubbles: true, cancelable: true })
      );

      this._vdom = h(
        (props: Props) => {
          this.getChildContext = () => props.context;
          const { context, children, ...rest } = props;
          return children ? cloneElement(children, rest) : null;
        },
        this._props,
        this.toVdom(this, this._vdomComponent)
      );
      (this.hasAttribute("hydrate") ? hydrate : render)(this._vdom, this._root);
    }

    attributeChangedCallback(name: string, oldValue: unknown, newValue: unknown) {
      if (!this._vdom) return;
      // Attributes use `null` as an empty value whereas `undefined` is more
      // common in pure JS components, especially with default parameters.
      // When calling `node.removeAttribute()` we'll receive `null` as the new
      // value. See issue #50.
      newValue = newValue == null ? undefined : newValue;
      const props: Props = {};
      props[name] = newValue;
      props[toCamelCase(name)] = newValue;
      this._vdom = cloneElement(this._vdom, props);
      render(this._vdom, this._root);
    }

    disconnectedCallback() {
      this._vdom = null;
      render(this._vdom, this._root);
    }

    toVdom(element: Node, component: ComponentDefinition | null): VNode<any> | null | string {
      // TextNode
      if (element.nodeType === 3) return (element as Text).data;
      if (element.nodeType !== 1) return null; // ElementNode

      const Slot = (props: Props, context: unknown) =>
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-expect-error
        h("slot", {
          ...props,
          ref: (r: HTMLElement) => {
            if (!r) {
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              //@ts-expect-error
              this.ref?.removeEventListener("_preact", this._listener);
            } else {
              this.ref = r;
              if (!this._listener) {
                this._listener = (event: CustomEvent<{ context?: unknown }>) => {
                  event.stopPropagation();
                  event.detail.context = context;
                };
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                //@ts-expect-error
                r.addEventListener("_preact", this._listener);
              }
            }
          },
        });

      // HTMLElement
      let children = [],
        props: Props = component ? { ref: this._props.ref } : {},
        i = 0,
        a = (element as HTMLElement).attributes,
        cn = element.childNodes;
      for (i = a.length; i--; ) {
        if (a[i].name !== "slot") {
          props[a[i].name] = a[i].value;
          props[toCamelCase(a[i].name)] = a[i].value;
        }
      }

      for (i = cn.length; i--; ) {
        const vnode = this.toVdom(cn[i], null);
        // Move slots correctly
        const name = (cn[i] as Element).slot;
        if (name) {
          props[name] = h(Slot, { name }, vnode);
        } else {
          children[i] = vnode;
        }
      }

      // Only wrap the topmost node with a slot
      const wrappedChildren = component ? h(Slot, null, children) : children;

      return component
        ? h(component, props, children)
        : h(element.nodeName.toLowerCase(), props as Omit<Props, "ref">, wrappedChildren);
    }
  }

  return customElements.define(tag, PreactCustomElement);
}
